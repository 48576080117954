declare var window :any;

export const header = function(){
    let subContents = document.querySelectorAll(".content_submenu");
    subContents.forEach(function(sub){
        let items = sub.querySelectorAll('.item>li');
        items.forEach(function(item){
            item.addEventListener('click', function(e){
                e.stopPropagation();
                let target = <HTMLElement> e.target;
                if (target === item){
                    if (target.classList.contains('active')){
                        target.classList.remove('active');
                    }else{
                        window.delHandle(items, 'active');
                        target.classList.add('active');
                    }
                }
            })
        })
    })

    let listMenuItems = document.querySelectorAll('.list_menu>li');
    listMenuItems.forEach(function(li){
        li.addEventListener('click', function(e){
            e.stopPropagation();

            let target = <HTMLElement> e.target;
            if (target === li){
                if (target.classList.contains('active')){
                    target.classList.remove('active');
                }else{
                    window.delHandle(listMenuItems, 'active');
                    target.classList.add('active');
                }
            }
        })
    })

    let mbToggle = document.querySelector('.mobile-toggle');
    mbToggle.addEventListener('click', function(e){
        e.preventDefault();
        mbToggle.classList.toggle('showed');
    })
}