// https://fancyapps.com/docs/ui/fancybox
// https://www.npmjs.com/package/axios
// https://greensock.com/
// https://swiperjs.com/get-started
declare var window:any;

declare var Swiper : any;
declare var $ : any;

// AOS.init({
//   once: true
// });

// import axios from "axios";

//////////////////////////////////////////////////////////
import {_base} from "./libraries/_base";
import {_imgClass} from "./libraries/_imgClass";
import {initPage} from "./libraries/pagination";

function copyMaker(){
  let copyElements = document.querySelectorAll('.copyMaker');
  copyElements.forEach(function(copyElement){
    if (!copyElement.classList.contains('i-cm-completed')){
      window.copyMaker(copyElement, function(status:string,text:string){
          window.addToastMsg(status, text);
      })
      copyElement.classList.add('i-cm-completed');
    }
  })
}

_base();
_imgClass();
initPage();
copyMaker();

// Layout
import {header} from "./layout/header";

// Page
import {index} from "./pages/index";

// lib

//////////////////////////////////////////////////////////////
window.addEventListener('DOMContentLoaded', function() {  
  // components

  // layout
  header();
  
  // ----- Page in here ----- //


  index();


  window.addEventListener('_imgClass', function(){
  })
})


/////////////////////WEB FULLPAGE///////////////////////////
// import loadingAni from "./initAnimation/loading";

// const fullpageLoad = function(){
//   let loading = document.querySelector('#loading-container');
//   loadingAni.aniIn();
//   // import animateFullpage from "./components/animateFullpage";
//   // animateFullpage.default();
  
//   window.addEventListener('load', function() {
//     // swiper_nav();
//     allJs();
//     setTimeout(function(){
//       loading.classList.add('hiddenLoading');
//       setTimeout(function(){
//         loading.parentElement.removeChild(loading);

//         // let utiSubMap = document.getElementById('UtilitiesSubPage');
//         // if (utiSubMap){
//         //   let map = utiSubMap.querySelector('.map');
//         //   map.classList.add('animateActive');
//         // }
//       }, 700)
//       common();
//     }, loadingAni.delay)
//   })
// }

// fullpageLoad();

/////////////////////WEB NORMAL///////////////////////////

