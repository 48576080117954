declare var window: any;
declare var Swiper: any;
declare var $: any;
declare var gsap: any;
declare var ScrollTrigger: any;
declare var THREE: any;

export const index = function () {
    if (document.querySelector('.sub-detail .post-culture')){
        let initPage = new window.pagination({
            selector: ".post-culture .pagination",
            items: ".post-culture .item",
            page: 6,
            scroll: {
                id: "idScroll"
            }
        })
    }

    if (document.querySelector('.sub-detail .post-work')){
        let initPage = new window.pagination({
            selector: ".post-work .pagination",
            items: ".post-work .item",
            page: 6,
            scroll: {
                id: "idScroll"
            }
        })
    }

    if (document.querySelector('.sub-detail .post-jobs')){
        let initPage = new window.pagination({
            selector: ".post-jobs .pagination",
            items: ".post-jobs .item",
            page: 6,
            scroll: {
                id: "idScroll"
            }
        })
    }
};